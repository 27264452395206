import React, { useEffect, useState} from "react";
import { Container, Row, Col, Form, Button} from "react-bootstrap";
import MenuList from "./MenuList";
import PropertySearch from "./PropertySearch";
import SiteSearch from "./SiteSearch";
import {replaceString} from "../../common/utils";

import $ from "jquery";

const PopupMenu = (props) => {

    const [active_menu, setActiveMenu] = useState(-1);
    const [menu_heading, setMenuHeading] = useState('');

    const clickHandler = (index) => {
        //console.log("active_menu", index, active_menu)
        if(index === active_menu){
            setActiveMenu(-1);
        } else{
            setActiveMenu(index);
        }
        
    };

    useEffect(()=>{
        setMenuHeading(props.menu_heading);
    },[props.menu_heading])

    useEffect(()=>{
        $(".service-menu").fadeIn('slow')
    },[props.search])

    
    // useEffect(() => {
    //     var primary_menu = menu_data && menu_data.length > 0 && menu_data.filter(item => item.Show_Popup_Menu === true && item.Label !== "Home" && item.Main_Parent === null);

    //     primary_menu.length > 0 && setActiveMenu(primary_menu[0].id);

    // },[props.menu_data])

    var menu_data = props.menu_data;

    //console.log("props_menu_heading", props.menu_heading.toLowerCase());

  return (
    <section className={`service-menu ${props.search ? "open-service" : ""}`}>
        <Container>
            <Row>
                <Col>
                    <div className="service">
                        <span onClick={props.handlerClick}>
                            <i className="icon-cross" onClick={props.closeSearch}></i>
                        </span>
                        <div className="h2">{replaceString(menu_heading)}</div>
                        {menu_heading.toLowerCase() === "site search" && 
                            <p>Search here to find our services or people. If you're searching for properties, <a href="javascript:;" onClick={()=>setMenuHeading('Find a Property')}>please click here</a>.</p>
                        }
                        {(menu_heading.trim().toLowerCase() === "find a service" || menu_heading.trim().toLowerCase() === "view our services" || menu_heading.trim().toLowerCase() === "what we do") && 
                            <p>Click + to expand and explore our services.</p>
                        }

                        {(menu_heading.trim().toLowerCase() === "find a service" || menu_heading.trim().toLowerCase() === "view our services" || menu_heading.trim().toLowerCase() === "what we do") && 
                            menu_data && menu_data.length > 0 && menu_data.filter(item => item.Show_Popup_Menu === true && item.Label !== "Home" && item.Main_Parent === null).map((item, i) => (

                                <MenuList active_menu={active_menu} clickHandler={clickHandler} i={item.id}   main_menu_data={menu_data.filter(main_menu_item => main_menu_item.Main_Parent && main_menu_item.Main_Parent.id === item.id)} {...item}  />
    
                            ))
                        }
                        

                        {(menu_heading.trim().toLowerCase() === "find a service" || menu_heading.toLowerCase() === "view our services" || menu_heading.toLowerCase() === "site search" || menu_heading.trim().toLowerCase() === "what we do") && <SiteSearch />}

                        

                        {menu_heading.trim().toLowerCase() === "find a property" || menu_heading.trim().toLowerCase() === "find out more" && <PropertySearch closeSearch={props.closeSearch} /> }

                        {/* {(menu_heading.trim().toLowerCase() === "what we do") && <SiteSearch />} */}
                        

                    </div>
                </Col>
            </Row>
        </Container>
    </section>
  );
};
export default PopupMenu;