import React, {useState} from "react";
import { Link } from "gatsby"
import Modal from "react-bootstrap/Modal"

import {youtube_parser, addTrailingSlash, numericWithDecimal} from "../components/common/utils";
import {gtag_report_conversion} from "../components/common/pageUtils"
import { useLocation } from "@reach/router"
import RequestAppraisal from "../components/forms/request_appraisal";

const BannerCTAModule = ({className, GQLPage, CTA_Module, openSearch, scrollToPos, setVideoId, setPlay, videoId}) => {
    const [isOpen,setIsOpen] = useState(false);
    const currentLocation = useLocation();
    var youtube_id = CTA_Module && CTA_Module.length > 0 &&  CTA_Module[0].Banner_Video_Link && youtube_parser(CTA_Module[0].Banner_Video_Link);

    var department = GQLPage?.select_menu && GQLPage?.select_menu?.Main_Parent !== null ? GQLPage?.select_menu?.Main_Parent.Label : GQLPage?.select_menu?.Label;
    var type = GQLPage?.select_menu && GQLPage?.select_menu?.Type;
    var sub_type = GQLPage?.select_menu && GQLPage?.select_menu?.Sub_Type;

    var page_url = typeof window !== "undefined" ? window.location.href : '';
    var to_user_id = GQLPage?.select_menu?.Form_User_ID ? GQLPage?.select_menu?.Form_User_ID : '';

    //console.log("youtube_id", youtube_id);

    const isExternalUrl = (link) =>{ 
        if(link?.indexOf("strettons") > -1 || link?.startsWith("/")){
            return false
        }else{
            return true
        }
        
    }

    const isNumerical = (link) =>{ 
        console.log(link + isNaN(link))
        if(isNaN(link)){
            return false
        }else{
            return true
        }        
    }


    return (
        <React.Fragment>
            {
                CTA_Module[0] && CTA_Module[0].Banner_CTA_Module.length > 0 && 
                <div className={className}>
                    {
                        CTA_Module[0].Banner_CTA_Module.map((item, index) =>{

                            var icon_cls = item?.Icon_ClassName && (item?.Icon_ClassName?.indexOf('icon') >= 0 ? item?.Icon_ClassName?.replace(/_/g,'-') : `icon-${item?.Icon_ClassName?.replace(/_/g,'-')}`);

                            return(
                                item?.CTA_Link === "Megamenu" ?
                                <a href={"javascript:;"} onClick={()=> {openSearch(item.CTA_Label.trim()); gtag_report_conversion()}} className={`btn ${item.CTA_Classname} ${item.Icon_ClassName ? '' : 'no_icon'}`}> 
                                    {item.CTA_Label}
                                    {item.Icon_ClassName && <i className={icon_cls}></i> }
                                </a>
                                :
                                item?.CTA_Link && (item.CTA_Link.trim() === "open_positions" || item.CTA_Link.trim() === "send_cv" || item.CTA_Link.trim() === "#contact" || item.CTA_Link.trim() === "contact" || item.CTA_Link.trim() === "contact_office" || item.CTA_Link.trim() === "#contact_office") ?
                                    <a href={"javascript:;"} onClick={(e)=>{scrollToPos(item.CTA_Link); gtag_report_conversion()}} className={`btn ${item.Icon_ClassName ? '' : 'no_icon'}`}>
                                        {item.Icon_ClassName && <i className={icon_cls}></i> }
                                        {item.CTA_Label}
                                    </a>
                                :
                                    // <Link to={item.CTA_Link} state={{"department":department ? department : "", "page_url": page_url, "type":type, "sub_type":sub_type, "to_user_id":to_user_id}} className={`btn ${item.Icon_ClassName ? '' : 'no_icon'}`}>
                                    //     {item.Icon_ClassName && 
                                    //         <i className={`icon-${item.Icon_ClassName.replace('_','-')}`}></i> 
                                    //     }
                                    //     {item.CTA_Label}
                                    // </Link>
                                    item?.CTA_Link?.indexOf("request-an-appraisal") > -1 ?
                                    <a href={`javascript:;`} onClick={() => {setIsOpen(true); gtag_report_conversion()}} className={`btn ${item.Icon_ClassName ? '' : 'no_icon'}`} >
                                        {item.Icon_ClassName && <i className={icon_cls}></i>}
                                        {item.CTA_Label}
                                    </a>
                                    :
                                        item?.CTA_Link?.indexOf("register-your-search") > -1 ?
                                        <Link to={addTrailingSlash(item.CTA_Link)} state={{"department":department ? department : "", "page_url": page_url, "type":type, "sub_type":sub_type, "to_user_id":to_user_id}} className={`btn ${item.Icon_ClassName ? '' : 'no_icon'}`}>
                                            {item.Icon_ClassName && <i className={icon_cls}></i>}
                                            {item.CTA_Label}
                                        </Link>
                                        :
                                        isExternalUrl(item?.CTA_Link)?

                                            (isNumerical(item?.CTA_Link)?
                                            <a href={`tel:${item?.CTA_Link}`} onClick={() => {gtag_report_conversion(item.CTA_Link)}} className={`btn desktop-views-points ${item.Icon_ClassName ? '' : 'no_icon'}`}>
                                            {item.Icon_ClassName && 
                                                <i className={icon_cls}></i> 
                                            }
                                            {item.CTA_Label}
                                            </a>
                                            :                                                
                                                <a href={addTrailingSlash(item?.CTA_Link)} onClick={() => {gtag_report_conversion(item.CTA_Link)}} className={`btn ${item.Icon_ClassName ? '' : 'no_icon'}`} target={'blanck'}>
                                                {item.Icon_ClassName && 
                                                    <i className={icon_cls}></i> 
                                                }
                                                {item.CTA_Label}
                                                </a>)

                                            :
                                                <Link to={addTrailingSlash(item?.CTA_Link)} onClick={() => {gtag_report_conversion(item.CTA_Link)}} state={{"department":department ? department : "", "page_url": page_url, "type":type, "sub_type":sub_type, "to_user_id":to_user_id}} className={`btn ${item.Icon_ClassName ? '' : 'no_icon'}`}>
                                                    {item.Icon_ClassName && 
                                                        <i className={icon_cls}></i> 
                                                    }
                                                    {item.CTA_Label}
                                                </Link>
                            )
                        })
                    }
                </div>
            }

            {
                CTA_Module && CTA_Module.length > 0 && CTA_Module[0].Banner_Video_Link && (
                    youtube_id ?
                    <div className="bann_vid" onClick={(e) => { setVideoId(youtube_id); setPlay(true);  }}>
                        <div className="video-buttons banner_video">
                            <strong className="video-btn">
                                <i className="icon-video-black"></i>
                            </strong>                                            
                        </div>
                        <span className="vid_txt">{CTA_Module && CTA_Module[0].Banner_Video_Label ? CTA_Module[0].Banner_Video_Label : "watch our video"}</span>
                    </div>
                    :
                    <div className="bann_vid">
                        <a href={CTA_Module[0].Banner_Video_Link } target="_blank">
                            <div className="video-buttons banner_video">
                                <strong className="video-btn">
                                    <i className="icon-video-black"></i>
                                </strong>                                            
                            </div>
                            <span className="vid_txt">{CTA_Module && CTA_Module[0].Banner_Video_Label ? CTA_Module[0].Banner_Video_Label : "watch our video"}</span>
                        </a>
                    </div>
                )
            }

            <Modal
            id="popup_modal_form"
            show={isOpen}
            onHide={() => setIsOpen(false)}
            backdrop="static"
            keyboard={false}
            className="member-contact-modal"
            > 
                <Modal.Header closeButton className="contact-close-btn memberPopup">
                    <Modal.Title className="w-100">
                        <h3 className="text-center content-primary-color mb-0">Request an Appraisal</h3>
                        <p>Complete the form and one of our local experts will be in touch to arrange your market appraisal.
                    </p>
                    </Modal.Title>                    
                </Modal.Header>
                <Modal.Body>
                    <RequestAppraisal department={department} page_url={page_url} type={type} sub_type={sub_type} to_user_id={to_user_id} popup_form={true} />
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}
export default BannerCTAModule
