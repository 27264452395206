import React, { useState, useRef, useEffect } from "react"
import Select, { components } from "react-select";
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import { navigate } from "@reach/router"
import "./PropertySearch.scss"
// Header component

const PropertySearch = (props) => {
    const options = [
        { value: "commercial", label: "Commercial" },
        { value: "auctions", label: "Auctions" },
        { value: "industrial", label: "Industrial" },
        { value: "residential", label: "Residential" }
    ]
    const searchLinks = [
        { key: "commercial", value: "/commercial-property/for-sale/" },
        { key: "auctions", value: "/auction-property/for-sale/" },
        { key: "industrial", value: "/commercial-property/for-sale/" },
        { key: "residential", value: "/residential-property/for-sale/" }
    ]

    const [type, setType] = useState('commercial');
    const [searchVal, setSearchVal] = useState('');

    const search = () => {
        let searchLink = searchLinks.find(option => option.key === type);

        let search = "in-london"
        if (searchVal) {
            search = searchVal
        }
        let url = `${searchLink.value}${search}`
        if (type == "industrial") {
            url = `${searchLink.value}${search}/type-industrial/`
        }
        navigate(url);
    }
    function handleChange(e) {
        setSearchVal(e.target.value);
    }

    return (
        <div className="banner_bottom_search">
            <Container >
            <div className="search_form">
                <Select
                    isSearchable={false}
                    value={options.filter(option => option.value === type)}
                    onChange={(e) => { setType(e.value) }}
                    options={[
                        { value: "commercial", label: "Commercial" },
                        { value: "auctions", label: "Auctions" },
                        { value: "industrial", label: "Industrial" },
                        { value: "residential", label: "Residential" }
                    ]}
                    classNamePrefix={"select-opt"}
                />
                <div className="search-box">
                    <i className="icon-map"></i>
                    <Form.Control placeholder="Property name or location" name="search" type="text" onChange={handleChange}></Form.Control>
                </div>

                <Button variant="primary" className="d-md-none">search</Button>
                <Button type="button" onClick={() => { search() }} className="search_button">
                    <i className="form-search"></i>
                </Button> 
                </div>
            </Container>
        </div>

    )
}
export default PropertySearch
