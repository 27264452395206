import React, { useState, useRef, useEffect } from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import Modal from "react-bootstrap/Modal"
import ScrollAnimation from 'react-animate-on-scroll';
import ScrollContainer from 'react-indiana-drag-scroll'

import { useLocation } from "@reach/router";

import { addTrailingSlash } from "../../common/utils";
import { gtag_report_conversion } from "../../common/pageUtils"
import Newsletter from "../../forms/newsletter-form-popup"
import PropertySearch from "./PropertySearch"

import "./Counter.scss"
// Header component

const Counter = (props) => {
    console.log('revamp', props)
    // Property details sticky nav
    const stickyRef = useRef();
    const [isOpen, setIsOpen] = useState(false);
    const scrollHandle = (event) => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        console.log('')
        if (scrollTop > 0) {
            stickyRef?.current?.classList.add('searchscrolled');
        } else {
            stickyRef?.current?.classList.remove('searchscrolled');
        }
    }
    useEffect(() => {
        if (props?.Module?.Banner_Bottom_Module?.filter(item => item.Content !== "" && item.Content !== null).length === 0) {
            window.addEventListener('scroll', scrollHandle);
            return function () {
                window.removeEventListener('scroll', scrollHandle);
            }
        }
    }, [])

    //console.log("Banner_Bottom_Module", props?.Module?.Banner_Bottom_Module)

    var department = (props.Menu_Link && props.Menu_Link.Main_Parent !== null) ? props.Menu_Link.Main_Parent.Label : props.Menu_Link?.Label;
    var type = props.Menu_Link && props.Menu_Link?.Type;
    var sub_type = props.Menu_Link && props.Menu_Link?.Sub_Type;
    //console.log("Menu_Link", department)

    var to_user_id = props?.Menu_Link?.Form_User_ID ? props?.Menu_Link?.Form_User_ID : '';

    const location = useLocation();


    return (
        <React.Fragment >
            {
                props.Module?.Banner_Bottom_Module && props.Module?.Banner_Bottom_Module?.length > 0 &&
                <div className={`counter ${props.Module.Banner_Bottom_Module[0].Content ? "" : "btm_link"}`} ref={stickyRef}>

                    <Container>
                        <Row>
                            <Col>
                                {
                                    props?.Module?.Banner_Bottom_Module?.filter(item => item.Content !== "" && item.Content !== null).length > 0 ?
                                        <section className="box-wrapper">
                                            {

                                                props.Module.Banner_Bottom_Module.map((item, index) => {
                                                    return (
                                                        <>
                                                            <div className="box">
                                                                <h3>{item.Heading}</h3>
                                                                <span className="sm-text">{item.Content}</span>
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }
                                        </section>
                                        :
                                        <ScrollContainer>
                                            <section className="box-wrapper">
                                                {
                                                    props.Module.Banner_Bottom_Module.length > 0 && props.Module.Banner_Bottom_Module.map((item, index) => {
                                                        return (
                                                            <>
                                                                <div className="box link_box">
                                                                    <h3>
                                                                        {(item?.Link?.indexOf('request-an-appraisal') > -1 || item?.Link?.indexOf('register-your-search') > -1) ?
                                                                            <Link to={addTrailingSlash(item.Link)} state={{ "department": department, "page_url": location.href, "type": type, "sub_type": sub_type, "to_user_id": to_user_id }} >{item.Heading}</Link>
                                                                            :
                                                                            item?.Link?.includes("newsletter-subscription") ? (
                                                                                <a href={`javascript:;`} onClick={() => { setIsOpen(true); }}>
                                                                                    {item.Heading}
                                                                                </a>
                                                                            )
                                                                                :
                                                                                <Link to={addTrailingSlash(item.Link)} onClick={(e) => { gtag_report_conversion(item.Link) }} state={{ "department": department, "page_url": location.href, "type": type, "sub_type": sub_type, "to_user_id": to_user_id }} >{item.Heading}</Link>
                                                                        }
                                                                    </h3>
                                                                </div>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </section>
                                        </ScrollContainer>
                                }
                            </Col>
                        </Row>

                    </Container>

                </div>
            }
            {
                props.Module?.Banner_Bottom_Search &&
                <PropertySearch />

            }
            <Modal
                id="popup_modal_form"
                show={isOpen}
                onHide={() => setIsOpen(false)}
                backdrop="static"
                keyboard={false}
                className="member-contact-modal"
                dialogClassName="modal-dialog-centered"
            >
                <Modal.Header closeButton className="contact-close-btn memberPopup">
                    {

                        <Modal.Title className="w-100">
                            <h3 className="content-primary-color mb-1">Sign up to hear from us</h3>
                            <p className="mb-0">Stay up to date and receive all of our latest news, insights and case studies straight to your inbox.</p>
                        </Modal.Title>
                    }

                </Modal.Header>
                <Modal.Body>
                    <Newsletter />
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}
export default Counter
